
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import { HasDesktopPart } from "@/mixins/properties/has-desktop-part";
  import { HasRepresentativeOptions } from "@/mixins/representatives/has-options";
  import { countryOptions } from "@/utils/select-options";
  import { Watch } from "vue-property-decorator";
  import { mask } from "vue-the-mask";
  import { HasRegisterNumber } from "@/mixins/has-register-number";
  import { FormCheckbox } from "@/components/forms";

  @Component({
    directives: { mask },
    components: {
      FormCheckbox,
    },
  })
  export default class PageBidPartDesktopRepresentatives extends Mixins(HasDesktopPart, HasRepresentativeOptions, HasRegisterNumber) {
    @Prop({ default: null }) value!: BidRepresentative[];

    @Prop({ default: "any" }) step!: number | "any";

    countryOptions = countryOptions;

    localValue: BidRepresentative[] = [];

    copyAddressFromDefault: boolean[] = [];

    addNewRepresentative() {
      if (!this.localValue) {
        this.localValue = [];
      }

      this.localValue.push({
        title: "",
        first_name: "",
        last_name: "",
        national_register_number: "",
        birth_date: "",
        birth_place: "",
        birth_country: "BE",
        street: "",
        number: "",
        additional: "",
        city: "",
        zip: "",
        country: "BE",
        phone: "",
        email: "",
      });

      this.handleInput();
    }

    /**
     * @description Remove the representative from the list by index
     *
     * @param index: number - the index of the representative to remove
     * @returns void
     */
    removeRepresentative(index: number) {
      this.localValue.splice(index, 1);

      this.handleInput();
    }

    /**
     * @description Handle the input event for a register number input and update the birthdate if not given already
     *
     * @param index
     */
    handleRegisterNumberChange(index: number) {
      const registerNumber = this.localValue[index].national_register_number;

      this.localValue[index].birth_date = this.getBirthDateFromRegisterNumber(registerNumber);
    }

    setDefaultAddress(value: boolean, index: number) {
      if (!this.localValue[index] || !value) {
        return;
      }

      let defaultAddress = this.localValue[0];

      this.localValue[index].street = defaultAddress.street;
      this.localValue[index].number = defaultAddress.number;
      this.localValue[index].additional = defaultAddress.additional;
      this.localValue[index].city = defaultAddress.city;
      this.localValue[index].zip = defaultAddress.zip;
      this.localValue[index].country = defaultAddress.country;
    }

    validateIfAddressIsDefault() {
      let defaultAddress = this.localValue[0];

      if (defaultAddress && defaultAddress.street !== "" && defaultAddress.zip !== "" && defaultAddress.city !== "") {
        this.localValue.forEach((representative, index) => {
          if (representative.street === defaultAddress.street && representative.zip === defaultAddress.zip && representative.city === defaultAddress.city) {
            this.copyAddressFromDefault[index] = true;
          }
        });
      }
    }

    @Watch("value")
    valueChanged(newValue: BidRepresentative[]) {
      if (!newValue || newValue.length === 0) {
        this.addNewRepresentative();
      } else {
        this.localValue = newValue;

        this.validateIfAddressIsDefault();
      }
    }
  }
